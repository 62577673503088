import React from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { Station } from '../models'
import { SignTurnRight } from 'react-bootstrap-icons'

type Props = {
	station: Station,
}

const InnerButton = (): JSX.Element => {
	return (
		<React.Fragment>
			<SignTurnRight />
			<span>Nawiguj</span>
		</React.Fragment>
	)
}

const StationNavigateButton = (props: Props): JSX.Element => {
	const desktopTo = `https://www.google.com/maps/dir/?api=1&destination=${props.station.coords.latitude},${props.station.coords.longitude}`
	let navigateComponent: JSX.Element
        = ( <a className='btn btn-primary' target="_blank" rel="noopener noreferrer" href={desktopTo}><InnerButton /></a> )

	if (isAndroid)
		navigateComponent = (<a className='btn btn-primary' href={`google.navigation:q=${props.station.coords.latitude}+${props.station.coords.longitude}`}><InnerButton /></a>)

	if (isIOS)
		navigateComponent = (<a className='btn btn-primary' href={`maps://app?daddr=${props.station.coords.latitude}+${props.station.coords.longitude}`}><InnerButton /></a>)

	return navigateComponent
}

export default StationNavigateButton
