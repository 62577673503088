import React, { createRef, useEffect } from 'react'
import './StationsList.scss'
import { Station } from '../models'
import StationDetails from '../station-details/StationDetails'
import { isMobile } from 'react-device-detect'
import { useAppSelector } from '../../hooks'

type Props = {
	station: Station,
	onShowOnMap?: () => void,
	isSelected: boolean,
	onSelect: (stationId: string) => void,
}

const StationListItem = (props: Props) => {
	const { isSelected, onSelect } = props
	const stationsLength = useAppSelector((state) => state.stations.stations.length)

	const ref = createRef<HTMLDivElement>()

	useEffect(() => {
		if (!ref?.current || !isSelected)
			return

		ref.current.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		})

	}, [isSelected, stationsLength]) // stationsLength is used to scroll to the selected station when the list is updated

	return (
		<div
			id={props.station.id}
			className={`station-list-item ${isSelected ? 'selected' : ''} ${isMobile ? 'mobile' : 'desktop'}`}
			onClick={() => onSelect(props.station.id)}
			ref={ref}
		>
			{!isMobile && <i className='click-hint'>Kliknij aby pokazać na mapie</i>}
			<StationDetails
				station={props.station}
				onShowOnMap={props.onShowOnMap}
				showOnMapVisible={true}
				canProposePrices={!isMobile}
			/>
		</div>
	)
}

export default StationListItem
