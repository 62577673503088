import { createSlice } from '@reduxjs/toolkit'

const initialState: {
	filterBy: {
		radius: number,
	},
	sortBy: 'price' | 'distance',
} = {
	filterBy: { radius: 3 },
	sortBy: 'distance',
}

export const stationsFilteringSlice = createSlice({
	name: 'stationsFiltering',
	initialState,
	reducers: {
		setRadius(state, action) {
			state.filterBy = {
				...state.filterBy,
				radius: action.payload,
			}
		},
		setSortBy(state, action) {
			state.sortBy = action.payload
		},
	},
})

export const { setRadius, setSortBy } = stationsFilteringSlice.actions

export default stationsFilteringSlice
