import React, { useState } from 'react'
import './MobileBottomBar.scss'
import StationList from '../stations-list/StationsList'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Station } from '../models'
import Spinner from 'react-bootstrap/Spinner'

type Props = {
	stations: Station[],
}

const MobileBottomBar = (props: Props) => {
	const [show, setShow] = useState<boolean>(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	return (
		<div className='mobile-bottom-bar'>
			<Button onClick={handleShow}>
				Lista stacji
			</Button>

			<Modal
				className='mobile-bottom-bar-modal'
				show={show}
				onHide={handleClose}
				scrollable={true}
			>
				<Modal.Header closeButton={true} />
				<Modal.Body>
					{/* <StationsFiltering /> */}
					{!props.stations.length
						? (
							<div className='loading-spinner'>
								<Spinner animation="grow" role="status" />
								<Spinner animation="grow" role="status" />
								<Spinner animation="grow" role="status" />
							</div>
						)
						: (
							<StationList
								stations={props.stations}
								onShowOnMap={handleClose}
							/>
						)}
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default MobileBottomBar
