type ConfigType = {
	ApiUrl: string,
	AdminMode: boolean,
	AD_B2C_Domain: string,
	AD_B2C_SignInPolicy: string,
	AD_B2C_ApplicationId: string,
}
const config: ConfigType = {
	ApiUrl: process.env.REACT_APP_API_URL || '',
	AdminMode: !!process.env.REACT_APP_ADMIN_MODE,
	AD_B2C_Domain: process.env.REACT_APP_AD_B2C_DOMAIN || '',
	AD_B2C_SignInPolicy: process.env.REACT_APP_AD_B2C_SIGN_IN_POLICY || '',
	AD_B2C_ApplicationId: process.env.REACT_APP_AD_B2C_APPLICATION_ID || '',
}

export default config
