import React, { useCallback, useMemo } from 'react'
import { getUrlToCopy } from './utils'
import shareOnMobile from '../../../utils/shareOnMobile'
import { isMobile } from 'react-device-detect'
import { Button } from 'react-bootstrap'
import { Copy, ShareFill } from 'react-bootstrap-icons'
import { ShareStationProps } from './types'

const ShareButton = (props: ShareStationProps) => {
	const shareData = useMemo<ShareData>(() => {
		return {
			title: 'Udostępnij stację',
			url: getUrlToCopy(props.station.id),
		}
	}, [props.station])

	const canShare = useMemo(() => {
		return navigator.canShare(shareData)
	}, [])

	const onShare = useCallback((e: any) => {
		e.stopPropagation()

		shareOnMobile(shareData)
	}, [props.station, isMobile])

	return (
		<Button
			className='share-btn'
			variant="outline-primary"
			onClick={onShare}
		>
			{isMobile || !canShare ? <ShareFill /> : <Copy />}
		</Button>
	)
}

export default ShareButton
