import React, { useCallback, useMemo, useRef, useState } from 'react'
import { ShareStationProps } from './types'
import { Button, Overlay, Tooltip } from 'react-bootstrap'
import { CheckLg, Copy } from 'react-bootstrap-icons'
import { isMobile } from 'react-device-detect'
import { getUrlToCopy } from './utils'

const CopyButton = (props: ShareStationProps) => {
	const [showTooltip, setShowTooltip] = useState(false)
	const [copied, setCopied] = useState(false)
	const target = useRef(null)

	const tooltipText = useMemo(() => {
		return copied
			? 'Skopiowano!'
			: 'Skopiuj do schowka'
	}, [copied])

	const onShare = useCallback((e: any) => {
		e.stopPropagation()

		navigator.clipboard.writeText(getUrlToCopy(props.station.id))

		setShowTooltip(true)
		setCopied(true)

		setTimeout(() => setShowTooltip(false), 700)
		setTimeout(() => setCopied(false), 5000)

	}, [props.station, isMobile])

	return (
		<React.Fragment>
			<Button
				ref={target}
				className='share-btn'
				variant="outline-primary"
				onClick={onShare}
				disabled={copied}
				onMouseEnter={() => !copied && setShowTooltip(true)}
				onMouseLeave={() => !copied && setShowTooltip(false)}
			>
				{copied ? <CheckLg /> : <Copy />}
			</Button>

			<Overlay
				target={target.current}
				show={showTooltip}
				placement="right">
				{(p) => (
					<Tooltip id="overlay-example" {...p}>
						{tooltipText}
					</Tooltip>
				)}
			</Overlay>
		</React.Fragment>
	)
}

export default CopyButton
