import React from 'react'
import './SelectedStationModal.scss'
import Modal from 'react-bootstrap/Modal'
import StationDetails from '../station-details/StationDetails'
import { Station } from '../models'
import useRouteSelectedStation from './SelectedStationContext'

type Props = {
	selectedStation: Station | undefined,
}

const StationSelectedModal = (props: Props) => {
	const { unselectStation } = useRouteSelectedStation()

	if (!props.selectedStation)
		return (<React.Fragment />)

	const onClose = () => unselectStation()

	return (
		<Modal
			className='station-selected-modal'
			show={!!props.selectedStation}
			onHide={onClose}
		>
			<Modal.Header closeButton={true} />
			<Modal.Body>
				<StationDetails
					station={props.selectedStation}
					showOnMapVisible={false}
				/>
			</Modal.Body>
		</Modal>
	)
}

export default StationSelectedModal
