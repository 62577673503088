import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Station } from './models'
import stationsApi from './stationsApi'
import { Coords } from '../geolocation/coords'
import { getCoordsStage, getDistanceFromLatLonInKm } from '../utils/mathUtilities'
import { RootStore } from '../store'

const initialState: {
	stations: Station[],
	searchedStages: Coords[],
	searchCoords: Coords | undefined,
} = {
	stations: [],
	searchedStages: [],
	searchCoords: undefined,
}

export const loadStations = createAsyncThunk(
	'stations/loadStations',
	async (coords: Coords, { getState, rejectWithValue }) => {
		const state = getState() as RootStore

		const searchedStages = state.stations.searchedStages
		const stageSearchCoords = getCoordsStage(coords)

		if (searchedStages.find((x) => x.latitude === stageSearchCoords.latitude && x.longitude === stageSearchCoords.longitude))
			return rejectWithValue({})

		const response = await stationsApi.getStations(stageSearchCoords, state.geolocation.myCoords)

		return response
	}
)

export const loadSelectedStation = createAsyncThunk(
	'stations/loadSelectedStation',
	async (stationId: string, { rejectWithValue, dispatch }) => {
		const coords = await stationsApi.getStationCoords(stationId)
		if (!coords)
			return rejectWithValue({})

		dispatch(loadStations(coords))

		return coords
	}
)

export const stationsSlice = createSlice({
	name: 'stations',
	initialState,
	reducers: {
		setSearchCoords(state, action) {
			if (!state.searchCoords || getDistanceFromLatLonInKm(state.searchCoords, action.payload) > 7)
				state.searchCoords = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadStations.fulfilled, (state, action) => {
			const stations = action.payload as Station[]

			if (!!stations.length) {
				const concatObjects = [...state.stations, ...stations]
				const distinctObjects = new Map(concatObjects.map((x) => [x.id, x])).values()
				const stationsToPut = Array.from(distinctObjects)

				state.stations = stationsToPut

				state.searchedStages = [
					...state.searchedStages,
					{
						latitude: action.payload[0].stageCoords.latitude,
						longitude: action.payload[0].stageCoords.longitude,
					},
				]
			}
		})
	},
})

export const { setSearchCoords } = stationsSlice.actions

export default stationsSlice
