import { StationAddress } from '../stations/models'

const getStationAddress = (address: StationAddress) => {
	const result: string[] = []

	if (address.street) {
		let street = ''
		street += `ul. ${address.street}`

		if (address.houseNumber)
			street += ' ' + address.houseNumber

		!!street && result.push(street)
	}

	if (address.city)
		result.push(address.city)

	return result.join(', ')
}

export default getStationAddress
