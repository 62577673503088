import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import useRouteSelectedStation from '../selected-station/SelectedStationContext'
import { followMyLocation, unfollowMyLocation } from './stationsMapSlice'
import { Crosshair2 } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'

const FollowMyLocation = () => {
	const dispatch = useAppDispatch()
	const { myCoords } = useAppSelector((state) => state.geolocation)
	const { isFollowingMyLocation } = useAppSelector((state) => state.stationsMap)
	const { unselectStation } = useRouteSelectedStation()
	const { selectedStationId } = useRouteSelectedStation()

	useEffect(() => {
		if (selectedStationId)
			dispatch(unfollowMyLocation())
	}, [selectedStationId])

	const onClick = useCallback((e: any) => {
		e.stopPropagation()
		if (myCoords) {
			unselectStation()
			dispatch(followMyLocation())
		}

	}, [myCoords])

	if (!myCoords)
		return (<React.Fragment />)

	return (
		<Button className={`leaflet-control leaflet-bar my-location-btn ${!isFollowingMyLocation && 'unfollow'}`} onClick={onClick}>
			<Crosshair2 />
		</Button>
	)
}

export default FollowMyLocation
