import React, { useEffect, useRef, useState } from 'react'
import './StationsMap.scss'
import { warsawPkinCoords } from '../../geolocation/consts'
import { setSearchCoords } from '../stationsSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Coords } from '../../geolocation/coords'
import { MapContainer, TileLayer } from 'react-leaflet'
import { Map } from 'leaflet'
import StationMapStationsCluster from './StationsMapCluster'
import MyCoordsMarker from './MyCoordsMarker'
import { Station } from '../models'
import useRouteSelectedStation from '../selected-station/SelectedStationContext'
import { unfollowMyLocation } from './stationsMapSlice'
import FollowMyLocation from './FollowMyLocationButton'

const goTo = (map: Map | null, coords: Coords) => {
	map?.panTo({
		lat: coords.latitude,
		lng: coords.longitude,
	})
}

type Props = {
	stations: Station[],
	selectedStation: Station | undefined,
}

const StationsMap = (props: Props) => {
	const { selectedStationId, selectedWithZoom } = useRouteSelectedStation()
	const { isGeolocationLoaded, myCoords } = useAppSelector((state) => state.geolocation)
	const { isFollowingMyLocation } = useAppSelector((state) => state.stationsMap)
	const myCoordsFounded = !!myCoords
	const dispatch = useAppDispatch()

	const map = useRef<Map | null>(null)
	const [mapCenter, setMapCenter] = useState<Coords | undefined>()
	const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout | undefined>()

	// set initial map center
	useEffect(() => {
		if (selectedStationId || !isGeolocationLoaded || mapCenter)
			return

		const coordsToPan = myCoords ?? warsawPkinCoords
		goTo(map.current, coordsToPan)
		setMapCenter(coordsToPan)
	}, [
		map.current,
		isGeolocationLoaded,
		myCoordsFounded,
		mapCenter,
		selectedStationId,
	])

	// follow my location
	useEffect(() => {
		if (!isFollowingMyLocation || !myCoords)
			return

		goTo(map.current, myCoords)
	}, [isFollowingMyLocation, myCoords])

	// refresh stations on each map position change
	useEffect(() => {
		if (mapCenter) {
			refreshTimeout && clearTimeout(refreshTimeout)
			setRefreshTimeout(
				setTimeout(() => {
					dispatch(setSearchCoords(mapCenter))
				}, 800)
			)
		}
	}, [mapCenter?.latitude, mapCenter?.longitude])

	// fly to selected station
	useEffect(() => {
		if (props.selectedStation) {
			setMapCenter({
				latitude: props.selectedStation.coords.latitude,
				longitude: props.selectedStation.coords.longitude,
			})
			const zoom = selectedWithZoom ? 18 : undefined
			const duration = selectedWithZoom ? 1.5 : 0.5

			map.current?.flyTo({
				lat: props.selectedStation.coords.latitude,
				lng: props.selectedStation.coords.longitude,
			}, zoom, { duration })
		}
	}, [props.selectedStation, selectedWithZoom])

	// set map center on each position change
	useEffect(() => {
		map.current?.addEventListener({
			zoomstart() {
				dispatch(unfollowMyLocation())
			},
			dragstart() {
				dispatch(unfollowMyLocation())
			},
			moveend() {
				setMapCenter({
					latitude: map.current?.getCenter().lat ?? 0,
					longitude: map.current?.getCenter().lng ?? 0,
				})
			},
		})

	}, [map.current])

	return (
		<React.Fragment>
			<MapContainer
				ref={map}
				className='map'
				center={{
					lat: warsawPkinCoords.latitude,
					lng: warsawPkinCoords.longitude,
				}}
				zoom={14}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>

				<StationMapStationsCluster stations={props.stations} />
				<MyCoordsMarker />

				<FollowMyLocation />
			</MapContainer>
		</React.Fragment>
	)
}

export default StationsMap
