const expireAtKey = (key: string): string => `${key}-expireAt`

const getOrCreateEntry = async <T>(
	key: string,
	factory: () => Promise<T>,
	expireAfterSeconds?: number): Promise<T> => {

	const finalExpireAfterMiliseconds: number = (expireAfterSeconds
		? expireAfterSeconds
		: 3600 /* 1 hour */) * 1000

	const currentTime = new Date().getTime()

	const expireAtValue = localStorage.getItem(expireAtKey(key))
	if (expireAtValue && parseInt(expireAtValue) > currentTime) {
		const result = localStorage.getItem(key)
		if (result)
			return JSON.parse(result) as T
	}

	const result = await factory()

	localStorage.setItem(expireAtKey(key), `${currentTime + finalExpireAfterMiliseconds}`)
	localStorage.setItem(key, JSON.stringify(result))

	return result
}

const remove = (key: string): void => {
	localStorage.removeItem(expireAtKey(key))
}

export default {
	getOrCreateEntry,
	remove,
}
