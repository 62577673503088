import React, { useState } from 'react'
import { StationPrices } from '../../models'
import { Button, Modal } from 'react-bootstrap'
import { Bell } from 'react-bootstrap-icons'
import { isMobile } from 'react-device-detect'
import ProposePricesForm from './ProposePricesForm'
import { useAuth } from '../../../../common/auth/AuthContainer'

type Props = {
	stationPrices: StationPrices,
}

const Confirm = () => {
	return (
		<p>Dziękujemy za zgłoszenie.</p>
	)
}

const StationProposePricesButton = (props: Props): JSX.Element => {
	const [showModal, setShowModal] = useState(false)
	const [showConfirm, setShowConfirm] = useState(false)
	const auth = useAuth()

	const handleShowModal = async () => {
		await auth.ensureLoggedIn()
		setShowModal(true)
	}

	const handleCloseModal = () => {
		setShowModal(false)
		setShowConfirm(false)
	}

	const handleOnSubmit = () => {
		setShowConfirm(true)

		setTimeout(() => {
			setShowModal(false)

			setTimeout(() => {
				setShowConfirm(false)
			}, 200)
		}, 1000)

	}

	return (
		<React.Fragment>
			<Button variant="primary" onClick={handleShowModal}>
				<Bell />
				<span>Zgłoś cenę</span>
			</Button>

			<Modal
				show={showModal}
				onHide={handleCloseModal}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop='static'
				fullscreen={isMobile ? true : ''}
			>
				<Modal.Header closeButton={true}>
					Zgłoś ceny
				</Modal.Header>
				<Modal.Body>
					{showConfirm
						? <Confirm />
						: (
							<ProposePricesForm
								onSubmit={handleOnSubmit}
								currentStationPrices={props.stationPrices} />
						)}
				</Modal.Body>
			</Modal>
		</React.Fragment>

	)
}

export default StationProposePricesButton
