import React from 'react'
import { Provider } from 'react-redux'
import './App.scss'
import Geolocation from './geolocation/geolocation'
import { store } from './store'
import StationsContainer from './stations/StationsContainer'
import AuthContainer from '../common/auth/AuthContainer'
import { SelectedStationProvider } from './stations/selected-station/SelectedStationContext'

const App = () => {
	return (
		<div className="app">
			<Provider store={store}>
				<Geolocation>
					<AuthContainer>
						<SelectedStationProvider>
							<StationsContainer />
						</SelectedStationProvider>
					</AuthContainer>
				</Geolocation>
			</Provider>
		</div>
	)
}

export default App
