import React, { useCallback } from 'react'
import './StationsList.scss'
import StationListItem from './StationListItem'
import { Station } from '../models'
import useRouteSelectedStation from '../selected-station/SelectedStationContext'
import { isMobile } from 'react-device-detect'

type Props = {
	stations: Station[],
	onShowOnMap?: () => void,
}

const StationList = (props: Props) => {
	const { selectedStationId, selectStationWithZoom } = useRouteSelectedStation()

	const onSelect = useCallback((stationId: string) => {
		const isSelected = stationId === selectedStationId
		if (isMobile || isSelected)
			return

		selectStationWithZoom(stationId)
	}, [
		selectedStationId,
		selectStationWithZoom,
		isMobile,
	])

	const stationListItems = props.stations.map(
		(x: Station) => (
			<StationListItem
				key={x.id}
				station={x}
				onShowOnMap={props.onShowOnMap}
				isSelected={x.id === selectedStationId}
				onSelect={onSelect}
			/>
		),
	)

	return (
		<div className='station-list'>
			{stationListItems}
		</div>
	)
}

export default StationList
