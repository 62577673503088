import { Coords } from '../geolocation/coords'

function deg2rad(deg: any) {
	return deg * (Math.PI / 180)
}

export function getDistanceFromLatLonInKm(coords1: Coords, coords2: Coords) {
	const R = 6371 // Radius of the earth in km
	const dLat = deg2rad(coords2.latitude - coords1.latitude) // deg2rad below
	const dLon = deg2rad(coords2.longitude - coords1.longitude)
	const a
        = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(deg2rad(coords1.latitude)) * Math.cos(deg2rad(coords2.latitude))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2)

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	const d = R * c // Distance in km
	return d
}

export function takeMeanPrice(prices: number[]) {
	return prices.reduce((a, b) => a + b, 0) / prices.length
}

export function displayDistance(distanceInKm?: number): string {
	if (!!distanceInKm && distanceInKm > 0) {
		if (distanceInKm < 1.0)
			return `${Math.floor(distanceInKm * 100) * 10} m`

		return `${distanceInKm.toFixed(1)} km`
	}

	return ''
}

export const getCoordsStage = (coords: Coords): Coords => {
	const step = 0.5

	const result = {
		latitude: Math.round(coords.latitude / step) * step,
		longitude: Math.round(coords.longitude / step) * step,
	}

	return result
}

export const getDiffDaysFromNow = (date: string): number => {
	const currentDateTime = Date.now()
	const diffTime = Math.abs(currentDateTime - new Date(date).getTime())
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

	return diffDays
}
