import axios, { InternalAxiosRequestConfig } from 'axios'
import config from '../config'
import { getAccessToken } from './auth/msal'

const authorized = axios.create({
	timeout: 30000,
	baseURL: config.ApiUrl,
})

const unauthorized = axios.create({
	timeout: 30000,
	baseURL: config.ApiUrl,
})

authorized.interceptors.request.use(
	async (cfg: InternalAxiosRequestConfig) => {
		if (cfg.headers)
			cfg.headers['Content-Type'] = 'application/json'

		const accessToken = await getAccessToken()
		if (cfg.headers && accessToken)
			cfg.headers['Authorization'] = 'Bearer ' + accessToken

		return cfg
	},
	(error) => {
		Promise.reject(error)
	}
)

unauthorized.interceptors.request.use(
	async (cfg: InternalAxiosRequestConfig) => {
		if (cfg.headers)
			cfg.headers['Content-Type'] = 'application/json'

		return cfg
	},
	(error) => {
		Promise.reject(error)
	}
)

export default {
	authorized,
	unauthorized,
}
