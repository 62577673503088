import React, { useEffect, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { Coords } from '../geolocation/coords'
import MobileBottomBar from './mobile-bottom-bar/MobileBottomBar'
import { Station } from './models'
import StationSelectedModal from './selected-station/SelectedStationModal'
import StationList from './stations-list/StationsList'
import StationsMap from './stations-map/StationsMap'
import { loadSelectedStation, loadStations } from './stationsSlice'
import loadStationsFromCloseStages from './loadStationsFromCloseStages'
import { getDistanceFromLatLonInKm } from '../utils/mathUtilities'
import useRouteSelectedStation from './selected-station/SelectedStationContext'
import { useAppDispatch, useAppSelector } from '../hooks'

const getFilteredStations = (
	stations: Station[],
	filtering: any,
	searchCoords: Coords
): Station[] => {
	let result: Station[] = stations

	result = result.filter((x) => getDistanceFromLatLonInKm(x.coords, searchCoords) <= 10)

	if (filtering.sortBy === 'distance')
		result.sort((a, b) => a.distanceInKm - b.distanceInKm)

	// if (action.payload.sortBy === 'price')
	// 	entriesToSort.sort((a, b) => b.distanceInKm - a.distanceInKm)

	return result
}

const StationsContainer = () => {
	const dispatch = useAppDispatch()
	const { selectedStationId } = useRouteSelectedStation()
	const stationsState = useAppSelector((state) => state.stations)
	const filtering = useAppSelector((state) => state.stationsFiltering)
	const selectedStation = useMemo(
		() => selectedStationId ? stationsState.stations.find((x) => x.id === selectedStationId) : undefined,
		[selectedStationId, stationsState.stations]
	)

	const stations = useMemo(() => {
		const filteredStations = stationsState.searchCoords
			? getFilteredStations(
				stationsState.stations,
				filtering,
				stationsState.searchCoords
			)
			: []

		return filteredStations
	}, [
		stationsState.stations,
		stationsState.searchCoords,
		filtering,
	])

	useEffect(() => {
		if (selectedStationId && !selectedStation)
			dispatch(loadSelectedStation(selectedStationId))
	}, [selectedStationId, selectedStation])

	useEffect(() => {
		const searchCoords = stationsState.searchCoords
		if (!searchCoords)
			return

		const fetch = async () => {
			await loadStationsFromCloseStages(searchCoords, async (coords: Coords) => { await dispatch(loadStations(coords)) })
		}

		fetch()
	}, [stationsState.searchCoords?.latitude, stationsState.searchCoords?.longitude])

	return (
		<React.Fragment>
			{!isMobile
				&& (
					<div className="left-sidebar">
						{/* <StationsFiltering /> */}
						<StationList stations={stations} />
					</div>
				)
			}
			<div className="main-content">
				<StationsMap
					stations={stations}
					selectedStation={selectedStation} />
				{isMobile && <MobileBottomBar stations={stations} />}
			</div>

			{isMobile && <StationSelectedModal selectedStation={selectedStation} />}
		</React.Fragment>
	)
}

export default StationsContainer
