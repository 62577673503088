import React, { useEffect } from 'react'
import { useGeolocated } from 'react-geolocated'
import { setMyCoords, setMyCoordsNotKnown } from './geolocation-slice'
import { useAppDispatch } from '../hooks'
import useRouteSelectedStation from '../stations/selected-station/SelectedStationContext'
import { isMobile } from 'react-device-detect'

const geolocationPermissionStatus = async () => {
	if (navigator.geolocation) {
		const result = await navigator.permissions.query({ name: 'geolocation' })
		return result.state
	}
}

const Geolocation = (props: any) => {
	const dispatch = useAppDispatch()
	const { selectedStationId } = useRouteSelectedStation()

	const result = useGeolocated({
		positionOptions: { enableHighAccuracy: true },
		watchPosition: true,
		userDecisionTimeout: 30000,
		suppressLocationOnMount: true,
		onSuccess: (position) => {
			dispatch(setMyCoords({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			}))
		},
		onError: () => {
			dispatch(setMyCoordsNotKnown())
		},
		isOptimisticGeolocationEnabled: false,
	})

	useEffect(() => {
		geolocationPermissionStatus()
			.then((status) => {
				if (status === 'granted' || !selectedStationId || !isMobile)
					result.getPosition()
			})
			.catch()
	}, [
		result.isGeolocationEnabled,
		selectedStationId,
		isMobile,
	])

	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	)
}

export default Geolocation
