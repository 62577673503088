import {
	LogLevel,
	Configuration,
} from '@azure/msal-browser'
import config from '../../config'

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
const b2cPolicies = {
	names: {
		signUpSignIn: config.AD_B2C_SignInPolicy,
		// forgotPassword: 'B2C_1_reset_v3',
		// editProfile: 'B2C_1_edit_profile_v2',
	},
	authorities: {
		signUpSignIn: { authority: `https://${config.AD_B2C_Domain}.b2clogin.com/${config.AD_B2C_Domain}.onmicrosoft.com/${config.AD_B2C_SignInPolicy}` },
		// forgotPassword: {
		//     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
		// },
		// editProfile: {
		//     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
		// },
	},
	authorityDomain: `${config.AD_B2C_Domain}.b2clogin.com`,
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig: Configuration = {
	auth: {
		clientId: config.AD_B2C_ApplicationId, // This is the ONLY mandatory field that you need to supply.
		authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
		knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
		redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
		postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
		navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
	},
	cache: {
		cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level: any, message: any, containsPii: any) => {
				if (containsPii)
					return

				switch (level) {
					case LogLevel.Info:
						return
					default:
						// eslint-disable-next-line no-console
						console.error(message)
				}
			},
		},
	},
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

const scopes = [`https://${config.AD_B2C_Domain}.onmicrosoft.com/api/scope1`]

const loginRequest = { scopes }

export { loginRequest, msalConfig }
