import { combineSlices, configureStore } from '@reduxjs/toolkit'
import stationsSlice from './stations/stationsSlice'
import stationsFilteringSlice from './stations/filtering/stationsFilteringSlice'
import geoLocationSlice from './geolocation/geolocation-slice'
import stationsMapSlice from './stations/stations-map/stationsMapSlice'

const reducer = combineSlices(
	stationsSlice,
	stationsFilteringSlice,
	geoLocationSlice,
	stationsMapSlice,
)

export const store = configureStore({ reducer })

export type RootStore = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
