import { Coords } from '../geolocation/coords'
import { Fuel, FuelPrice, Station, StationPrices } from './models'
import { getDistanceFromLatLonInKm } from '../utils/mathUtilities'
import localStorageCache from '../../common/localStorageCache'
import apiClient from '../../common/axios'

const getBrandFuels = async (): Promise<{[brand: string]: Fuel[]}> => {
	return localStorageCache.getOrCreateEntry(
		'fuelBrands',
		async () => (await apiClient.unauthorized.get<{[brand: string]: Fuel[]}>('/stations/brands/fuels')).data
	)
}

const getPricesOrderedByBrand = (stationPrices: StationPrices, brandFuels: Fuel[]): FuelPrice[] => {
	const result: FuelPrice[] = []

	brandFuels
		.forEach((brandFuel) => {
			const fuelPrice = stationPrices.prices.find((price) => price.fuelId === brandFuel.id)
			if (fuelPrice) {
				fuelPrice.fuel = brandFuel
				result.push(fuelPrice)
			}
		})

	return result
}

const fillPrices = async (
	stations: Station[],
	prices: StationPrices[]
) => {
	const brandsFuels: {[brand: string]: Fuel[]} = await getBrandFuels()

	stations.forEach((station) => {
		const stationPrices = prices.find((p) => p.stationId === station.id)
		station.prices = {
			stationId: station.id,
			brand: station.brand,
			prices: stationPrices ? getPricesOrderedByBrand(stationPrices, brandsFuels[station.brand]) : [],
			lastUpdate: stationPrices?.lastUpdate,
		}

		station.prices.prices.forEach(async (price) => {
			const brandFuel = brandsFuels[station.brand].find((f) => f.id === price.fuelId)
			if (brandFuel)
				price.fuel = brandFuel
		})
	})
}

const fillDistances = (stations: Station[], myCoords: Coords | undefined) => {
	if (!myCoords)
		return

	stations.forEach((station) => {
		station.distanceInKm = getDistanceFromLatLonInKm(station.coords, myCoords)
	})
}

const getStations = async (coords: Coords, myCoords: Coords | undefined): Promise<Station[]> => {
	const params = {
		latitude: coords.latitude,
		longitude: coords.longitude,
	}

	const getStationsTask = apiClient.unauthorized.get<Station[]>('/stations', { params })
	const getPricesTask = apiClient.unauthorized.get<StationPrices[]>('/prices', { params })

	const stations = (await getStationsTask).data
	fillDistances(stations, myCoords)

	const prices = (await getPricesTask).data
	await fillPrices(stations, prices)

	return stations
}

const proposePrices = async (
	stationId: string,
	prices: {fuelId: string, price: number}[]
): Promise<void> => {
	return apiClient.authorized.post(`/prices/${stationId}/propose`, prices)
}

const proposePricesByPictures = async (
	stationId: string,
	pics: string[]
): Promise<void> => {
	return apiClient.authorized.post(`/prices/${stationId}/proposeByPics`, pics)
}

const getStationCoords = async (stationId: string): Promise<Coords | undefined> => {
	return (await apiClient.unauthorized.get<Coords | undefined>(`/stations/${stationId}/coords`)).data
}

export default {
	getStations,
	proposePrices,
	proposePricesByPictures,
	getBrandFuels,
	getStationCoords,
}
