import React from 'react'
import MarkerClusterGroup from '@changey/react-leaflet-markercluster'
import { Station } from '../models'
import StationMapMarker from './StationMapMarker'
import useRouteSelectedStation from '../selected-station/SelectedStationContext'

type Props = {
	stations: Station[],
}

const StationMapStationsCluster = (props: Props) => {
	const { stations } = props
	const { selectedStationId, selectStation } = useRouteSelectedStation()

	return (
		<MarkerClusterGroup>
			<React.Fragment>
				{stations
					.map((x: Station) => (
						<StationMapMarker
							key={x.id}
							station={x}
							isSelected={x.id === selectedStationId}
							onSelect={() => selectStation(x.id)}
						/>
					))}
			</React.Fragment>
		</MarkerClusterGroup>
	)
}

export default StationMapStationsCluster
