import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { Fuel, FuelPrice } from '../../models'
import { PlusLg, DashLg } from 'react-bootstrap-icons'
import { isMobile } from 'react-device-detect'

const longPressChange = (intervalRef: MutableRefObject<NodeJS.Timeout | null>, callback: () => void) => {
	callback()

	if (intervalRef.current) return

	intervalRef.current = setTimeout(() => {
		intervalRef.current = setInterval(() => {
			callback()
		}, 30)
	}, 150)
}
const stopLongPress = (intervalRef: MutableRefObject<NodeJS.Timeout | null>) => {
	if (intervalRef.current) {
		clearTimeout(intervalRef.current)
		clearInterval(intervalRef.current)
		intervalRef.current = null
	}
}

const getFloat = (numb: number) => numb < 0 ? 0 : parseFloat(numb.toFixed(2))

const getHandlers = (start: () => void, stop: () => void) => {
	if (isMobile) {
		return {
			onTouchStart: start,
			onTouchEnd: stop,
		}
	}

	return {
		onMouseDown: start,
		onMouseUp: stop,
		onMouseLeave: stop,
	}
}

const FuelFormInput = (props: {formik: any, fuel: Fuel, fuelPrice?: FuelPrice}) => {
	const intervalRef = useRef<NodeJS.Timeout | null>(null)
	const [formValue, setFormValue] = useState<number | undefined>(props.fuelPrice?.price ?? 0)

	const increment = () => longPressChange(intervalRef, () => setFormValue((prev) => prev ? getFloat(prev + 0.01) : 0.01))
	const decrement = () => longPressChange(intervalRef, () => setFormValue((prev) => prev ? getFloat(prev - 0.01) : 0))

	useEffect(() => {
		return () => stopLongPress(intervalRef)
	}, [])

	useEffect(() => {
		props.formik.setFieldValue(props.fuel.id, formValue)
	}, [formValue])

	const incrBtnProps = getHandlers(increment, () => stopLongPress(intervalRef))
	const decrBtnProps = getHandlers(decrement, () => stopLongPress(intervalRef))

	return (
		<div className='fuel-form-input'>
			<InputGroup className="mb-3">
				<InputGroup.Text className='prevent-select fuel-name'>{props.fuel.name}</InputGroup.Text>
				<Form.Control
					id={props.fuel.id}
					type="number"
					inputMode='decimal'
					value={formValue}
					onChange={(evt) => evt.target.value ? setFormValue(getFloat(parseFloat(evt.target.value))) : setFormValue(undefined)}
					step={0.01}
					min={0}
					onFocus={(evt) => evt.target.select()}
					onBlur={(evt) => evt.target.value ? setFormValue(getFloat(parseFloat(evt.target.value))) : setFormValue(0)}
				/>
				<InputGroup.Text
					className='prevent-select change-price-btn increment'
					{...incrBtnProps}
					onContextMenu={(e)=> e.preventDefault()}
				>
					<PlusLg />
				</InputGroup.Text>
				<InputGroup.Text
					className='prevent-select change-price-btn decrement'
					{...decrBtnProps}
					onContextMenu={(e)=> e.preventDefault()}
				>
					<DashLg />
				</InputGroup.Text>
			</InputGroup>
		</div>
	)
}

export default FuelFormInput
