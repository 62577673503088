import { useEffect, useState } from 'react'
import stationsApi from '../stationsApi'
import { Fuel } from '../models'

export const useBrandFuels = (brand: string): Fuel[] => {
	const [brandFuels, setBrandFuels] = useState<{[brand: string]: Fuel[]}>({})

	useEffect(() => {
		const load = async () => {
			const result = await stationsApi.getBrandFuels()
			setBrandFuels(result)
		}

		load()
	}, [])

	return brandFuels[brand] ?? []
}
