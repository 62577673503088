import React from 'react'
import { isMobile } from 'react-device-detect'
import ShareButton from './ShareButton'
import CopyButton from './CopyButton'
import { ShareStationProps } from './types'

const ShareStationButton = (props: ShareStationProps): JSX.Element => {
	return isMobile
		? <ShareButton {...props} />
		: <CopyButton {...props} />
}

export default ShareStationButton
