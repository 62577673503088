import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { createContext, useCallback, useContext, useEffect } from 'react'

type SelectedStationType = {
	selectedStationId: string | undefined,
	selectedWithZoom: boolean,
	selectStation: (stationIdToSelect: string) => void,
	selectStationWithZoom: (stationIdToSelect: string) => void,
	unselectStation: () => void,
}

export const SelectedStationContext = createContext<SelectedStationType>({ } as SelectedStationType)

export const useRouteSelectedStation = () => useContext(SelectedStationContext)

export const SelectedStationProvider = (props: {children: any}) => {
	const navigate = useNavigate()
	const { stationId } = useParams()
	const [selectedWithZoom, setSelectedWithZoom] = React.useState<boolean>(true)

	const selectStationCallback = useCallback((stationIdToSelect: string) => {
		setSelectedWithZoom(false)
		navigate('/' + stationIdToSelect, { replace: !!stationId })
	}, [!!stationId])

	const selectStationWithZoomCallback = useCallback((stationIdToSelect: string) => {
		setSelectedWithZoom(true)
		navigate('/' + stationIdToSelect, { replace: !!stationId })
	}, [!!stationId])

	const unselectStationCallback = useCallback(() => {
		stationId && navigate(-1)
	}, [!!stationId])

	useEffect(() => {
		if (stationId) {
			navigate('/', { replace: true })
			navigate('/' + stationId)
		}
	}, [])

	return (
		<SelectedStationContext.Provider
			value={{
				selectedStationId: stationId,
				selectedWithZoom: selectedWithZoom,
				selectStation: selectStationCallback,
				selectStationWithZoom: selectStationWithZoomCallback,
				unselectStation: unselectStationCallback,
			}}>
			{props.children}
		</SelectedStationContext.Provider>
	)
}

export default useRouteSelectedStation
