import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Station } from '../models'
import { Marker } from 'react-leaflet'
import L from 'leaflet'
import { getDiffDaysFromNow } from '../../utils/mathUtilities'

type Props = {
	station: Station,
	isSelected: boolean,
	onSelect: () => void,
}

const stationSelectedIconPath = 'station-selected.svg'
const outdatedStationIconPath = 'station-outdated.svg'
const dayPlusStationIconPath = 'station-day-plus.svg'
const updatedStationIconPath = 'station-updated.svg'

const getIcon = (lastUpdate: string | undefined) => {
	if (!lastUpdate)
		return outdatedStationIconPath

	const diffDays = getDiffDaysFromNow(lastUpdate)

	if (diffDays > 7)
		return outdatedStationIconPath

	if (diffDays > 1)
		return dayPlusStationIconPath

	return updatedStationIconPath
}

const StationMapMarker = (props: Props) => {
	const defaultIconPath = useMemo(() => getIcon(props.station.prices?.lastUpdate), [props.station.prices?.lastUpdate])
	const [iconPath, setIconPath] = useState<string>(defaultIconPath)
	const { isSelected } = props

	const hoverStation = () => setIconPath(stationSelectedIconPath)

	const leaveStation = useCallback(() => {

		if (isSelected) return

		setIconPath(defaultIconPath)
	}, [isSelected])

	useEffect(() => {
		if (isSelected)
			hoverStation()
		else
			leaveStation()
	}, [isSelected])

	return (
		<Marker
			icon={L.icon({
				iconUrl: iconPath,
				iconSize: L.point(40, 40),
			})}
			position={{
				lat: props.station.coords.latitude,
				lng: props.station.coords.longitude,
			}}
			eventHandlers={{
				mouseover() { hoverStation() },
				mouseout() { leaveStation() },
				click() { props.onSelect() },
			}}
		/>
	)
}

export default StationMapMarker
