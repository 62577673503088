import { Coords } from '../geolocation/coords'
import { getCoordsStage } from '../utils/mathUtilities'

const loadStationsFromCloseStages = async (
	searchCoords: Coords,
	load: (coords: Coords) => Promise<void>
) => {
	const searchCoordsStage = getCoordsStage(searchCoords)
	const closeStages: Coords[] = [searchCoordsStage]

	for (let latitude = searchCoords.latitude - 0.1; latitude <= searchCoords.latitude + 0.1; latitude += 0.2) {
		for (let longitude = searchCoords.longitude - 0.1; longitude <= searchCoords.longitude + 0.1; longitude += 0.2) {
			const stage = getCoordsStage({
				latitude,
				longitude,
			})

			if (!closeStages.find((x) => x.latitude === stage.latitude && x.longitude === stage.longitude))
				closeStages.push(stage)
		}
	}

	for (const stage of closeStages)
		await load(stage)
}

export default loadStationsFromCloseStages
