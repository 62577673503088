import { createSlice } from '@reduxjs/toolkit'
import { Coords } from './coords'

const initialState: {
	myCoords?: Coords,
	isGeolocationLoaded: boolean,
} = {
	myCoords: undefined,
	isGeolocationLoaded: false,
}

export const geoLocationSlice = createSlice({
	name: 'geolocation',
	initialState,
	reducers: {
		setMyCoords(state, action: { type: string, payload: Coords }) {
			state.myCoords = {
				latitude: action.payload.latitude,
				longitude: action.payload.longitude,
			}
			state.isGeolocationLoaded = true
		},
		setMyCoordsNotKnown(state) {
			state.myCoords = undefined
			state.isGeolocationLoaded = true
		},
	},
})

export const { setMyCoords, setMyCoordsNotKnown } = geoLocationSlice.actions

export default geoLocationSlice
