import React, { useCallback, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import './StationDetails.scss'
import { Station } from '../models'
import { displayDistance, getDiffDaysFromNow, getDistanceFromLatLonInKm } from '../../utils/mathUtilities'
import getStationAddress from '../../utils/getStationAddress'
import StationNavigateButton from './NavigateToButton'
import { isMobile } from 'react-device-detect'
import StationProposePricesButton from './propose-prices/ProposePricesButton'
import { PinMap } from 'react-bootstrap-icons'
import config from '../../../config'
import useRouteSelectedStation from '../selected-station/SelectedStationContext'
import ShareStationButton from './share-station/ShareStationButton'
import { useAppSelector } from '../../hooks'

type Props = {
	station: Station,
	showOnMapVisible?: boolean,
	onShowOnMap?: () => void,
	canProposePrices?: boolean,
}

const StationDetails = (props: Props): JSX.Element => {
	const { selectStationWithZoom, selectedStationId } = useRouteSelectedStation()
	const myCoords = useAppSelector((state) => state.geolocation.myCoords)
	const diffDaysText = useMemo(() => {
		if (!props.station.prices?.lastUpdate)
			return ''
		const diffDays = getDiffDaysFromNow(props.station.prices?.lastUpdate)

		return `Ostatnia aktualizacja ${diffDays} dni temu`
	}, [props.station.prices?.lastUpdate])

	const isCloseEnough = useMemo(() => {
		if (config.AdminMode)
			return true

		if (!myCoords)
			return false

		return getDistanceFromLatLonInKm(myCoords, props.station.coords) < 0.2
	}, [myCoords])

	const isSelected = useMemo(
		() => selectedStationId === props.station.id,
		[props.station.id, selectedStationId]
	)

	const onShowStationOnMap = useCallback(() => {
		if (props.onShowOnMap)
			props.onShowOnMap()

		if (!isSelected)
			selectStationWithZoom(props.station.id)
	}, [props.station.id, isSelected])

	return (
		<div className='station-details'>
			<div className='upper-section'>
				<div className='title'>
					<p className='name'>{props.station.name}</p>
					<i className='address'>{getStationAddress(props.station.address)}</i>
					<p className='distance'>{displayDistance(props.station.distanceInKm)}</p>
				</div>
				<ShareStationButton station={props.station} />
			</div>

			<p className='last-update'>{diffDaysText}</p>
			<div className='oils'>
				{props.station.prices.prices.map((fuelPrice) => (
					<p key={fuelPrice.fuel.name} className='oil'>
						{fuelPrice.fuel.name}: {fuelPrice.price.toFixed(2)} {fuelPrice.priceCurrency}
					</p>
				))}
			</div>

			<div className='action-buttons'>
				{((isMobile && props.showOnMapVisible) ?? true) && (
					<Button onClick={onShowStationOnMap}>
						<PinMap />
						<span>Pokaż na mapie</span>
					</Button>
				)}
				<StationNavigateButton station={props.station} />
				{(props.canProposePrices ?? true)
					&& isCloseEnough
					&& <StationProposePricesButton stationPrices={props.station.prices} />}
			</div>
		</div>
	)
}

export default StationDetails
