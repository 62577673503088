import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { loginRequest, msalConfig } from './msalConfig'

const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (
	!msalInstance.getActiveAccount()
  && msalInstance.getAllAccounts().length > 0
) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event: any) => {
	if (
		(event.eventType === EventType.LOGIN_SUCCESS
      || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      || event.eventType === EventType.SSO_SILENT_SUCCESS)
    && event.payload.account
	)
		msalInstance.setActiveAccount(event.payload.account)
})

export const getAccessToken = async (): Promise<string | undefined> => {
	const account = msalInstance.getActiveAccount()
	if (account) {
		try {
			const accessTokenResponse = await msalInstance.acquireTokenSilent({
				...loginRequest,
				account: account,
			})

			return accessTokenResponse?.accessToken
		}
		catch (error) {
			return undefined
		}
	}

	return undefined
}

export default msalInstance
