const shareOnMobile = (shareData: ShareData, fallback?: (message: string) => void) => {
	if (navigator.share === undefined) {
		fallback?.(
			'Can\'t share on this, make sure you are running on Android or iOS devices'
		)
		return
	}
	if (!shareData.title) {
		fallback?.('Title is required')
		return
	}

	try {
		if (navigator.canShare && navigator.canShare(shareData)) {
			navigator
				.share(shareData)
				.catch((error) => {
					fallback?.(error.message)
				})
		}
	}
	catch (error: any) {
		fallback?.(error.message)
	}
}

export default shareOnMobile
